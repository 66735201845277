@import "styles/style.module.scss";

.carousel {
  padding-bottom: 32px;
  &.addBottomPadding {
    padding-bottom: 57px;
  }
  .dynamicSlider {
    img[class*="slick-prev"] {
      left: 16px;
      z-index: 1;
      width: 32px;
      height: 32px;
    }
    img[class*="slick-next"] {
      right: 16px;
      z-index: 1;
      width: 32px;
      height: 32px;
    }
    img[class*="slick-disabled"] {
      opacity: 0;
    }
    div[class*="slick-track"] {
      margin: 0;
    }
    div[class*="slick-slide"] {
      > div {
        margin-right: 12px;
        &:first-child {
          margin-bottom: 12px;
        }
      }
      &:first-of-type {
        > div {
          margin-left: 0;
        }
      }
      &:last-of-type {
        > div {
          margin-right: 0;
        }
      }
    }
    ul[class*="slick-dots"] {
      bottom: -27px;
    }
    div[aria-hidden*="true"] {
      a {
        visibility: hidden;
        transition: visibility 0.7s linear;
      }
      button {
        visibility: hidden;
        transition: visibility 0.7s linear;
      }
    }
  }

  .itemCarousel {
    img[class*="slick-prev"] {
      left: 0px;
      width: 88px;
      height: 160px;
    }
    img[class*="slick-next"] {
      right: 0px;
      width: 88px;
      height: 160px;
    }
    img[class*="slick-disabled"] {
      width: 60px;
    }
  }

  .removeAllMargin {
    div[class*="slick-slide"] {
      > div {
        margin: 0;
      }
    }
  }

  .removeRightMargin {
    @include for-size(phone) {
      margin-right: -16px;
    }
  }
}
