.grid {
  display: grid;
  grid-gap: 6px;
  grid-auto-flow: row;
  width: 100%;
  .Item {
    min-height: 30px;
    justify-self: center;
    width: 100%;
  }
}

@media only screen and (max-width: 767.9px) {
  .grid {
    gap: 12px !important;
  }
}
