@import "styles/style.module.scss";

.doctorCard {
  background: $white;
  @include border-radius(8px);
  padding: 12px;
  border: 1px solid $grey3;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  margin-bottom: 32px;

  @include for-size(desktop) {
    width: 350px;
  }

  .badge {
    background: linear-gradient(90deg, $warningColor 43.5%, #8f6614 100%);
    color: $white;
    padding: 3px 8px;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 1px;
    @include border-radius(2px);
    text-transform: uppercase;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 8px;

    .doctorImage {
      width: 62px;
      height: 78px;
      @include border-radius(4px);
      object-fit: cover;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 4px;

      h2 {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }

      .specialty {
        font-size: 12px;
        color: $grey7;
        font-weight: 500;
        line-height: 16px;
      }

      .highlighted {
        display: flex;
        gap: 15px;

        span {
          color: $infoColor;
          font-size: 10px;
          font-weight: 600;
          line-height: 14px;
          letter-spacing: 1px;
          text-transform: uppercase;
          position: relative;

          &::before {
            content: "";
            width: 3px;
            height: 3px;
            @include border-radius(50%);
            background-color: $infoColor;
            position: absolute;
            left: -8px;
            top: 50%;
            transform: translateY(-50%);
          }

          &:first-child {
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }

  .bookButton {
    background: $brandColor6;
    color: $white;
    border: none;
    padding: 6px 16px;
    width: 100%;
    @include border-radius(8px);
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
  }

  .availability {
    color: $white;
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
  }
}
