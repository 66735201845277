@import "styles/style.module.scss";

.viewAllText {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #106c89;
  cursor: pointer;
  flex-shrink: 0;
  @include for-size(desktop) {
    padding: 10px 16px;
  }
  @include for-size(phone) {
    height: 32px;
    padding: 4px 0px 4px 16px;
  }
}
