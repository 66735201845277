@import "styles/style.module.scss";

.sectionHeader {
  @include flex(center, space-between, row);
  margin-bottom: 10px;
  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    span {
      padding-left: 10px;
    }
  }
  @include for-size(phone) {
    margin-bottom: 12px;
    h2 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      span {
        padding-left: 8px;
      }
    }
  }
}
