@import "styles/style.module.scss";

.carousel {
  padding-bottom: 32px;
  &.addBottomPadding {
    padding-bottom: 57px;
  }
  .dynamicSlider {
    img[class*="slick-prev"] {
      left: 16px;
      z-index: 1;
      width: 32px;
      height: 32px;
    }
    img[class*="slick-next"] {
      right: 16px;
      z-index: 1;
      width: 32px;
      height: 32px;
    }
    img[class*="slick-disabled"] {
      opacity: 0;
    }
    div[class*="slick-list"] {
      // margin: 0 -10px;
      display: flex;
    }
    div[class*="slick-track"] {
      margin: 0;
      display: flex;
    }
    div[class*="slick-slide"] {
      > div {
        margin: 0 10px;
        height: 100%;
      }
    }
    div[aria-hidden*="true"] {
      a {
        // visibility: hidden;
        transition: visibility 0.7s linear;
      }
      button {
        visibility: hidden;
        transition: visibility 0.7s linear;
      }
    }

    &.pastSearches {
      div[class*="slick-slide"] {
        > div {
          margin: 0;
        }
        flex-shrink: 0;
      }

      div[class*="slick-track"] {
        margin: 0;
        display: flex;
        gap: 12px;
      }
    }
  }

  .itemCarousel {
    img[class*="slick-prev"] {
      left: 0px;
      width: 88px;
      height: 160px;
    }
    img[class*="slick-next"] {
      right: 0px;
      width: 88px;
      height: 160px;
    }
    img[class*="slick-disabled"] {
      width: 60px;
    }
  }

  .removeAllMargin {
    div[class*="slick-list"] {
      margin: 0;
    }
    div[class*="slick-slide"] {
      > div {
        > div {
          margin: 0;
          // > div {
          > a {
            > img {
              height: auto !important;
              width: 100% !important;
            }
          }
          // }
        }
      }
    }
  }

  .removeRightMargin {
    @include for-size(phone) {
      margin-right: -16px;
    }
  }
  ul[class*="slick-dots"] {
    display: flex !important;
    height: 4px;
    justify-content: center;
    @include for-size(desktop) {
      bottom: -20px;
    }
    @include for-size(phone) {
      bottom: -16px;
    }
    li {
      width: 4px;
      height: 4px;
      button {
        border: none;
        width: 4px;
        height: 4px;
        background: $grey5;
        @include border-radius(10px);
        padding: 0;
        cursor: pointer;
        &::before {
          content: none;
        }
      }
      &[class*="slick-active"] {
        width: 12px;
        button {
          width: 12px;
          background-color: $brandColor5;
        }
      }
    }
  }
}

.dynamicSlider:has(.pastSearchCard) {
  min-height: unset;
}

.pastSearchCard {
  flex: 0 0 auto;
  width: auto !important;
}

.dummyCard {
  visibility: hidden;
}
