@import "styles/sass/partials/_colors";

.skeleton {
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
}

.widgetWrapper {
  background: $grey1;
}
