@import "../../../styles/style.module.scss";

.pageData {
  padding: 20px;
  position: relative;
  background: $white;

  @media only screen and (max-width: 600px) {
    padding: 20px 20px 40px;
    border-top: 1px dashed $primaryColor;
    border-bottom: 1px dashed $primaryColor;
  }
}

.seoContentWrap {
  .seoContent {
    @media only screen and (max-width: 600px) {
      max-height: 509px;
      overflow: hidden;
    }
    &.open {
      @media only screen and (max-width: 600px) {
        max-height: none;
      }
    }
    ul,
    ol {
      margin: 10px 0;
      padding: 0 0 0 30px;
      list-style: disc;
    }
    p {
      margin: 10px 0;
    }
    @include for-size(phone) {
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }

  .expandBtn {
    font-size: 13px;
    line-height: 17px;
    color: $secondaryColor;
    font-family: $fontBold;
    @extend .textUppercase;
    border: none;
    outline: none;
    background: transparent;
    right: 20px;
    bottom: 12px;
    display: none;

    @media only screen and (max-width: 600px) {
      display: inline-block;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 14px;
    font-family: $fontSemiBold;
    line-height: 18px;
    margin: 20px 0 10px;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.5;
  }

  a {
    font-family: $fontBold;
  }
}

/** override css for new design */
.newContainer {
  padding: 20px 0px;
  @media only screen and (max-width: 600px) {
    padding: 0 0 20px;
    border-top: none;
    border-bottom: none;
  }

  .seoContentWrap {
    .seoContent {
      ul,
      ol {
        margin: 0;
        padding: 0 0 0 20px;
      }
    }

    .expandBtn {
      color: $brandColor6;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 20px;
      margin: 24px 0 12px;
      color: $grey10;
    }

    p,
    li,
    ul,
    span {
      line-height: 16px;
      color: $grey8;
      font-size: 12px;
    }

    a {
      font-family: $fontBold;
    }
  }
}
