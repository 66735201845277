@import "styles/style.module.scss";

.gridMainSection {
  padding-bottom: 32px;
}

.sectionHeader {
  @include flex(center, space-between, row);
  margin-bottom: 10px;
  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    span {
      padding-left: 10px;
    }
  }
  @include for-size(phone) {
    margin-bottom: 12px;
    h2 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      span {
        padding-left: 8px;
      }
    }
  }

  button {
    padding: 0;

    &:hover,
    &:focus {
      background: transparent;
      box-shadow: none;
      border: none;
    }
  }
}

.grid {
  display: grid;
  grid-gap: 6px;
  grid-auto-flow: row;
  width: 100%;
  .Item {
    min-height: 30px;
    justify-self: center;
    width: 100%;
  }

  // &.show_12 .Item:nth-child(n + 13) {
  //   display: none;
  // }

  // &.show_14 .Item:nth-child(n + 15) {
  //   display: none;
  // }

  // &.show_8 .Item:nth-child(n + 9) {
  //   display: none;
  // }

  // &.show_6 .Item:nth-child(n + 7) {
  //   display: none;
  // }

  @include for-size(phone) {
    gap: 12px !important;
  }
}
